import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—February 2011" />
	<NewsHeader />
    <h2>February 2011</h2>




	<h3 className="blogdate">
	Wednesday, February 02, 2011
	</h3>

	<h4 className="blogitemtitle">
	New release! Everyelsewhere EP
	</h4>
	   <div className="blogitembody">
	   <p>
	It is with a great sense of accomplishment that I announce my latest
	release - <i>Everyelsewhere EP</i>! I've worked quite some time on it -
	it's been a great developmental experience for me in all aspects of
	music production: arrangement, recording, mixing, mastering, artwork,
	etc. The best part of it all for me was working with other people to get
	it done - musician and artist friends who I am grateful to and enjoyed
	working with. The release webpage says the rest; from this point I plan
	to do some travelling, living, eating, and writing... and ultimately
	some more music production!
	</p>
	<ul>
	<li>
	<Link to="/music/03/">Everyelsewhere EP (click me)</Link>
	</li>
	</ul>
	<p>
	You are welcome to listen and share using the players/links provided.
	</p>
	<p>
	Thanks!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 16:03 +1300</p>
	   </div>






	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
